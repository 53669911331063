<template>

  <div class="row" ref="contactUsView">

    <div class="col-12">

      <card>
        <div slot="header">
          <div class="header-content">
            <div class="form-title">
              <h4 class="card-title">
                {{ formTitle }}
              </h4>
            </div>
            <div class="header-actions">
              <router-link   v-if="$store.getters['auth/checkAccess']('contact-us/get')"
                             v-tooltip.top-center="'Go back'"
                             class="btn-warning btn-simple btn-link"
                             :to="'/contact-us'">
                <span class="btn-label px-2">
                    <i class="fas fa-arrow-left" style="font-size: 30px"></i>
                  </span>
              </router-link>
              <a v-if="$store.getters['auth/checkAccess']('contact-us/export')"
                 v-tooltip.top-center="'Print'"
                 class="btn-primary btn-simple btn-link"
                 @click="printDownload">
                  <span class="btn-label px-2">
                    <i class="fas fa-print" style="font-size: 30px;color: #15aabf"></i>
                  </span>
              </a>
              <a v-if="$store.getters['auth/checkAccess']('contact-us/export')"
                 v-tooltip.top-center="'Download as pdf'"
                 class="btn-primary btn-simple btn-link"
                 @click="downloadPdf">
                  <span class="btn-label px-2">
                    <i class="fas fa-file-pdf" style="font-size: 30px;color: #15aabf"></i>
                  </span>
              </a>
            </div>
          </div>
        </div>

        <div id="contactus-message" class="card-body">
          <div class="container">
            <div class="row contact-info">
              <div class="col-12 col-md-6 col-lg-6">
                <div class="typo-line">
                  <p>
                    <span class="category">Name</span>
                    {{ getValueOrDash(contactUsRequestDetails, 'full_name') }}
                  </p>
                </div>
                <div class="typo-line">
                  <p>
                    <span class="category">Email</span>
                    {{ getValueOrDash(contactUsRequestDetails, 'email') }}
                  </p>
                </div>
                <div class="typo-line">
                  <p>
                    <span class="category">Phone</span>
                    {{ getValueOrDash(contactUsRequestDetails, 'phone') }}
                  </p>
                </div>
                <div class="typo-line">
                  <p>
                    <span class="category">Company</span>
                    {{ getValueOrDash(contactUsRequestDetails, 'company') }}
                  </p>
                </div>
                <div class="typo-line">
                  <p>
                    <span class="category">Position</span>
                    {{ getValueOrDash(contactUsRequestDetails, 'position') }}
                  </p>
                </div>
                <div class="typo-line">
                  <p>
                    <span class="category">Date</span>
                    {{ getValueOrDash(contactUsRequestDetails, 'created_at') }}
                  </p>
                </div>
              </div>
            </div>
            <div class="category">Message :</div>
            <p class="message-content">
              {{ getValueOrDash(contactUsRequestDetails, 'message') }}
            </p>
          </div>
        </div>

        <div class="card-footer text-right">

        </div>
      </card>


    </div>

  </div>
</template>
<script>
import { jsPDF } from "jspdf";

export default {

  components: {},

  data() {
    return {
      id: undefined,
      formTitle: "",
      submitting: false,

      contactUsRequestDetails: null,

    };
  },

  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.contactUsView
    });

    this.id = this.$route.params['id'];
    this.formTitle = "Contact Us Request Details";
    if (this.id !== undefined) {
      this.getContactUsRequest();
    } else {
      this.$notify({
        message: "Contact Us Request Not Found",
        timeout: 2000,
        type: 'danger'
      });
      this.loader.hide();
    }
  },


  methods: {

    getContactUsRequest() {
      this.axios.post("contact-us/get", {id: +this.id}).then((response) => {
        this.contactUsRequestDetails = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.$notify({
            message: "Contact Us Request Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    getValueOrDash(obj, key) {
      if (obj && obj[key]) {
        return obj[key];
      }
      return "-";
    },
    printDownload () {
      let w = window.open()
      w.document.write(document.getElementById('contactus-message').innerHTML)
      w.document.close();
      w.setTimeout(function () {
        w.print()
      }, 1000)
    },
    downloadPdf(){




      // this.axios.post('contact-us/download-pdf',{id: +this.id})
      //   .then((response) => {
      //     console.log(response)
      //   });



      const pdf = new jsPDF({
        orientation: 'l',
        unit: 'px',
        format: 'letter',
        putOnlyUsedFonts:false,
        floatPrecision: 5 // or "smart", default is 16
      });
      // pdf.html(document.getElementById('contactus-message').innerHTML, {
      //   callback: function (pdf) {
      //     pdf.save('DOC.pdf');
      //   }, maxWidth: 1000
      // })
        pdf.text(document.getElementById('contactus-message').innerText,15, 15, {
          maxWidth: 550
        });
      pdf.save('DOC.pdf');

    }
  }
}
</script>

<style>
.header-content{
  display: flex;
  justify-content: space-between;
}
.typo-line{
  padding-left: 65px;
  margin-bottom: 20px;
}
.contact-info{
  margin-bottom: 20px;
}
.category{
  color: #888888;
}
/*.message-content{*/
/*  font-size: 16px !important*/
/*}*/
</style>
